<template>
  <form id="futuro-customer-debts" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-debt.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Dívidas</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mt-3 mb-3 px-3">
        <div class="col-12 totalizer debts-value-container">
          <span>Saldo devedor:</span>
          <span>{{ formatCurrency(debtsTotal) }}</span>
        </div>
      </div>
      <div class="row align-items-center px-3 mb-4">
        <template v-if="!isDetail">
          <button class="btn btn-orange col-auto mx-1" @click.prevent="addDebt">
            Adicionar dívida
          </button>
        </template>
      </div>
      <template v-if="isInterview">
        <div
          v-for="(debt, index) in debts"
          :key="`debt-${index}`"
          class="inner-card mb-3"
        >
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label :for="`debtType-${index}`" class="form-label">
                Tipo da dívida
              </label>
              <textarea
                :id="`debtType-${index}`"
                v-model="debt.typeDebt"
                type="text"
                :disabled="isDetail"
                class="form-control"
                rows="4"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-6 col-12">
              <label :for="`debtReason-${index}`" class="form-label">
                O que te levou a contrair essa dívida?
              </label>
              <textarea
                :id="`debtReason-${index}`"
                v-model="debt.reasonDebt"
                type="text"
                :disabled="isDetail"
                class="form-control"
                rows="4"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-4 col-12">
              <label :for="`debtStartDate-${index}`" class="form-label">
                Início
              </label>
              <input
                :id="`debtStartDate-${index}`"
                v-model="debt.startDate"
                v-mask="'##/##/####'"
                type="tel"
                :disabled="isDetail"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].startDate.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].startDate.length
                  "
                >
                  Você deve informar uma data válida
                </span>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label :for="`debtValue-${index}`" class="form-label">
                Valor
              </label>
              <input
                :id="`debtValue-${index}`"
                v-model.lazy="debt.value"
                v-money="moneyFormat"
                :disabled="isDetail"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].value.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].value.length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label :for="`debtInstallments-${index}`" class="form-label">
                Parcelas
              </label>
              <input
                :id="`debtInstallments-${index}`"
                v-model="debt.installments"
                :disabled="isDetail"
                type="number"
                class="form-control"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-4 col-12">
              <label :for="`debtFees-${index}`" class="form-label">
                Juros CET
              </label>
              <input
                :id="`debtFees-${index}`"
                v-model.lazy="debt.fees"
                v-money="percentageFormat"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].fees.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].fees.length
                  "
                >
                  O valor é inválido
                </span>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label :for="`debtAmountPaid-${index}`" class="form-label">
                Valor da parcela
              </label>
              <input
                :id="`debtAmountPaid-${index}`"
                v-model.lazy="debt.amountPaid"
                v-money="moneyFormat"
                :disabled="isDetail"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].amountPaid.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].amountPaid.length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label :for="`debtPaidInstallments-${index}`" class="form-label">
                Parcelas pagas
              </label>
              <input
                :id="`debtPaidInstallments-${index}`"
                v-model="debt.paidInstallments"
                type="number"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-12">
              <label :for="`debtCurrentBalance-${index}`" class="form-label">
                Saldo atual
              </label>
              <input
                :id="`debtCurrentBalance-${index}`"
                v-model.lazy="debt.currentBalance"
                v-money="moneyFormat"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].currentBalance
                      .length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].currentBalance
                      .length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3 justify-content-end">
            <div class="col-auto">
              <template v-if="!isDetail">
                <button class="btn btn-red" @click.prevent="removeDebt(index)">
                  Excluir dívida
                </button>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!isInterview">
        <div
          v-for="(debt, index) in debts"
          :key="`debt-${index}`"
          class="inner-card mb-3"
        >
          <div class="row mb-3 gy-3">
            <div class="col-md-4 col-12">
              <label :for="`debtType-${index}`" class="form-label">
                Tipo da dívida
              </label>
              <textarea
                :id="`debtType-${index}`"
                v-model="debt.typeDebt"
                type="text"
                class="form-control"
                rows="4"
                @blur="preSave()"
              />
            </div>

            <div class="col-md-4 col-12">
              <label :for="`debtValue-${index}`" class="form-label">
                Valor
              </label>
              <input
                :id="`debtValue-${index}`"
                v-model.lazy="debt.value"
                v-money="moneyFormat"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].value.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].value.length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <label :for="`debtAmountPaid-${index}`" class="form-label">
                Valor da parcela
              </label>
              <input
                :id="`debtAmountPaid-${index}`"
                v-model.lazy="debt.amountPaid"
                v-money="moneyFormat"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].amountPaid.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.debts.$dirty &&
                    v$.debts.$each.$response.$errors[index].amountPaid.length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3 justify-content-end">
            <div class="col-auto">
              <button class="btn btn-red" @click.prevent="removeDebt(index)">
                Excluir dívida
              </button>
            </div>
          </div>
        </div>
      </template>

      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getPercentageFormat } from "@/helpers/formatting";
import {
  floatToMoney,
  floatToPercentage,
  moneyToFloat,
  dateToString,
  integerToString,
} from "@/helpers/converters";
import { getDebtsPayload } from "@/helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "@/modules/shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      debts: [],
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      debts: {
        $each: helpers.forEach({
          typeDebt: {},
          reasonDebt: {},
          startDate: {
            minLength: minLength(10),
          },
          value: {
            minLength: minLength(7),
          },
          installments: {},
          fees: {
            minLength: minLength(7),
          },
          amountPaid: {
            minLength: minLength(7),
          },
          paidInstallments: {},
          currentBalance: {
            minLength: minLength(7),
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      formatCurrency: types.FORMAT_CURRENCY,
      formatedCurrency: types.FORMATED_CURRENCY
    }),
    debtsTotal: function () {
      return _.sumBy(this.debts, (debt) => {
        return moneyToFloat(debt.currentBalance);
      });
    },
    moneyFormat: function () {
      return this.formatedCurrency
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getDebts: types.GET_DEBTS,
      autoSaveDebts: types.AUTO_SAVE_DEBTS,
    }),
    ...mapMutations({
      mutateDebts: types.MUTATE_DEBTS,
    }),
    async fetchDebts() {
      this.loading = true;
      try {
        const response = await this.getDebts(this.$route.params.customerId);
        this.debts = response.data?.debts.map((debt) => {
          const {
            type_debt,
            reason_debt,
            start_date,
            value,
            installments,
            fees,
            amount_paid,
            paid_installments,
            current_balance,
          } = debt;

          return {
            typeDebt: type_debt,
            reasonDebt: reason_debt,
            startDate: dateToString(start_date),
            value: floatToMoney(value),
            installments: integerToString(installments),
            fees: floatToPercentage(fees),
            amountPaid: floatToMoney(amount_paid),
            paidInstallments: integerToString(paid_installments),
            currentBalance: floatToMoney(current_balance),
          };
        });
        const payload = getDebtsPayload({
          token: this.token,
          debts: this.debts,
        });
        this.mutateDebts(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as dívidas do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchDebts()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    addDebt() {
      this.debts.push({
        typeDebt: "",
        reasonDebt: "",
        startDate: "",
        value: "",
        installments: "",
        fees: "",
        amountPaid: "",
        paidInstallments: "",
        currentBalance: "",
      });
      this.preSave();
    },
    removeDebt(i) {
      this.debts.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload = getDebtsPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateDebts(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveDebts(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as dívidas do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  &.debts-value-container {
    background-image: var(--red-gradient);
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
</style>
